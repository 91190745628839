footer {
  background-color: #eee;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-start; */
  padding: 0 30px;
}

.footer-column {
  display: flex;
  text-align: start;
  flex-direction: column;
}

.footer-logo {
  max-width: 30vmin;
  height: auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 25px;
}

.about-text {
  font-weight: 400;
  max-width: 25em;
}

.social-icons {
  display: flex;
  flex-direction: row;
}

.app-icon {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.mailto-icon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-column p {
  font-size: large;
  font-weight: 500;
}

.footer-column li {
  font-weight: 400;
}

.footer-column li:hover {
  /* cursor: pointer; */
  transition: ease-in 0.2s;
  color: #ffc000;
}

.line {
  background: #d3d3d3;
  height: 1px;
  margin: 20px 30px;
}

.copyright {
  text-align: left;
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-logo {
    max-width: 50vmin;
  }
}
