header {
  max-height: 85vh;
  /* height: 100%; */
  width: 100%;
  color: white;
}

.svg-bg {
  background: url("../../assets/primary-layered-waves.svg");
  aspect-ratio: 960/540;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.app-header {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text-header {
  text-align: left;
  color: black;
}

.left-header-container {
  flex: 1;
  width: 100%;
  height: 100%;
  padding-left: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-header-container {
  flex: 1;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-title {
  font-size: calc(1rem + 8vmin);
  margin-top: 0.25em;
  margin-bottom: 0.5em;
  font-weight: 600;
}

.header-sub-title {
  font-weight: 500;
}

.store-icons-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: auto;
}

.store-icons-container img {
  height: 100%;
  margin: 10px;
  object-fit: contain;
}

.mobile-svg {
  max-height: 70vh;
  width: auto;
  margin-right: 6.5em;
}

.header-col {
  color: #fa9c1b;
}

@media screen and (max-width: 768px) {
  header {
    max-height: 200vh;
  }
  .app-header {
    flex-direction: column;
    height: auto;
  }
  .left-header-container {
    min-height: 100vh;
    /* height: auto; */
    order: 1;
    padding-left: 0;
    background: url("../../assets/primary-layered-waves.svg");
    aspect-ratio: 960/540;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .right-header-container {
    min-height: 100vh;
    justify-content: center;
    order: 2;
  }
  .store-icons-container {
    flex-direction: column;
  }
  .store-icons-container img {
    flex-shrink: 0;
  }
  .header-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .text-header {
    text-align: center;
    margin-bottom: 30vh;
  }
  .mobile-svg {
    margin-right: 0;
  }
  .svg-bg {
    background: #ffc000;
  }
  .header-sub-title {
    padding: 0 20px;
  }
}
