.accordion-item {
  border: 1px transparent;
  border-radius: 10px;
  margin: 15px 0;
  overflow: hidden;
  text-align: left;
}

.accordion {
  max-width: 90%;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #eee;
  font-weight: 700;
  font-size: large;
}

.accordion-title:hover {
  background-color: #e3e3e3;
}

.accordion-title,
.accordion-content {
  padding: 1.5rem;
}

.accordion-content {
  max-height: 0;
  display: flex;
  overflow: hidden;
  transition: max-height 0.1s, padding 0.1s;
  padding: 0 1.5rem;
  background-color: #eee;
}

.show {
  max-height: 1000px;
  padding: 1.5rem;
  transition: max-height 0.1s, padding 0.1s;
}

.icon {
  transition: transform 0.3s;
}

.rotate {
  transform: rotate(135deg);
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}
