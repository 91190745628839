.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.bubble-card-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.bubble-container {
  position: relative;
  border-radius: 25px;
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  margin-bottom: 150px;
}

.bubble-content {
  padding: 10px 30px 120px 30px;
  border-radius: 25px;
  transition: padding 0.5s ease-in-out;
  background-color: white;
  min-width: 200px;
  min-height: 180px;
}

.bubble-content h2 {
  font-weight: 500;
}

.bubble-img {
  position: absolute;
  bottom: -100px;
  left: 50%;
  height: 200px;
  width: 200px;
  border-radius: 150px;
  transform: translateX(-50%);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  transition: transform 0.5s ease-in-out;
}

@media screen and (max-width: 768px) {
  .bubble-card-container {
    margin: 0;
  }
}
