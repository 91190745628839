.coming-soon-container {
  position: relative;
  margin: 0 0px;
  border-radius: 25px;
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  overflow: hidden;
  width: 75%;
}

.coming-soon-banner {
  position: absolute;
  padding: 10px 100px;
  right: -85px;
  bottom: 15px;
  background-color: #ffc000;
  transform: rotate(-45deg);
  font-size: 18px;
  font-weight: 600;
  box-shadow: rgba(11, 43, 158, 0.15) 0px 6px 20px -6px;
}

.coming-soon-content {
  padding: 0 30px 20px 30px;
  /* max-width: 90%; */
}

.coming-soon-content h2 {
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .coming-soon-content {
    padding: 0 30px 80px 30px;
  }
  .coming-soon-container {
    width: 100%;
  }
}
