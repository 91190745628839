.guide-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 0 0px;
}

.lottie-container {
    height: 300px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.guide-header {
    font-size: xx-large;
    margin-top: -30px;
}

.guide-sub-header {
    font-size: medium;
    font-weight: 600;
    color: gray;
    padding: 0 25px;
    margin-top: -10px;
}


@media screen and (max-width: 768px) {      
    .guide-sub-header {
        font-size: large;
        font-weight: 500;
    }
}

