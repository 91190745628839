.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    width: 300px;
    margin: 20px 10px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card img {
    width: 100%;
    height: 100%;
    position: relative;
}

.content {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    transition: 0.6s;
}

.content h2 {
    color: whitesmoke;
    font-weight: 600;
}

.card:hover .content {
    opacity: 1;
}

.card:hover {
    transform: scale(1.03);
}