nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
  height: 80px;
  /* padding: 0 0.5rem; */
}

.nav-logo {
  width: auto;
  height: 50px;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: black;
  height: 100%;
  white-space: nowrap;
  font-weight: 600;
}

.nav-link:hover {
  color: #ffc000;
}

.nav-bar {
  display: none;
  color: black;
}

.nav-menu {
  display: flex;
  align-items: center;
  z-index: 100;
}

.nav-btn {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ffc000;
  color: white;
  border: none;
  outline: none;
  padding: 10px 22px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  font-size: inherit;
  /* box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02); */
  width: 100%;
}

.nav-btn:hover {
  transition: all 0.2s ease-in-out;
  background-color: black;
}

.nav-icon {
  display: flex;
  padding: 0.5rem 1rem;
}

.nav-tab-menu {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-bar {
    display: flex;
    font-size: 3rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .nav-link {
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
  }
  .nav-menu {
    display: none !important;
  }
  .nav-tab-menu {
    height: 100%;
    width: 100%;
    background-color: white;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    color: black;
    padding-top: 0.5rem;
    margin-top: 80px;
    justify-content: space-between;
  }
}
