body {
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
  max-width: 100%;
}

.primary-bg {
  background: linear-gradient(#ffc000, #ffe134);
}

section {
  min-height: 40vh;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 30px;
}

.extend {
  margin: 15vh 0;
}

.main-title {
  font-size: calc(2rem + 4vmin);
  font-weight: 600;
  margin-bottom: 5vh;
}

.d-flex {
  display: flex;
}

.title {
  font-size: calc(2rem + 4vmin);
  font-weight: 600;
  margin: 1rem 0;
}

.sub-title {
  font-weight: 500;
  font-size: 1.5rem;
}

.left-header {
  text-align: left;
}

.right-header {
  text-align: right;
}

.primary-color-header {
  color: #ffc000;
}

.secondary-color-header {
  color: white;
}

.about-container {
  margin-top: 25vh;
}

.about-sub-title {
  font-weight: 500;
  padding-top: 1.5rem;
  margin-left: 14rem;
  margin-right: 14rem;
}

.sharing-img {
  height: 300px;
  width: auto;
  border-radius: 50%;
  margin-left: 80px;
  padding: 20px 10px;
}

.sharing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
}

.highlight {
  padding: 6px;
  border-radius: 10px;
  font-size: 1rem;
  background-color: #fff8dc;
  color: #ffc000;
  font-weight: 600;
  max-width: 100px;
  text-align: center;
}

.bottom-svg {
  aspect-ratio: 960/540;
  background: url("./assets/bottom-svg.svg");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  min-height: 30vh;
}

.bottom-svg-extend {
  margin-top: 35vh;
}

.infinite-loop-container {
  display: flex;
  margin-top: 8vh;
}

.infinite-loop-container h2 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.component-header {
  font-size: 35px;
  font-weight: 700;
  padding: 30px 20px;
}

.pt-m {
  padding-top: 20px;
}

.section-header {
  font-size: 40px;
  font-weight: 700;
  color: white;
}

.button {
  background: white;
  /* background: linear-gradient(#ffc000, #ffe134); */
  color: black;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 40px;
  border-radius: 10px;
  margin: 10px 0px 0px 0px;
  cursor: pointer;
  border-width: 0;
  box-shadow: rgba(11, 43, 158, 0.15) 0px 6px 20px -6px;
}

.button:hover {
  opacity: 80%;
}

@media screen and (max-width: 768px) {
  .extend {
    margin: 10vh 0;
  }
  .bottom-svg-extend {
    margin-top: 10vh;
  }
  .sharing-container {
    flex-direction: column;
    margin-top: 2rem;
  }
  .sharing-img {
    margin-left: 0;
  }
  .about-sub-title {
    margin-left: 0;
    margin-right: 0;
  }
}
